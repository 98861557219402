import type { Blocks, MessageDataPlot } from 'chat/interfaces/messages';
import { BACKEND_STRINGS } from 'common/interfaces/enums';
import type { Dataset, Model } from 'common/interfaces/interfaces';
import type { Plot } from 'playground/interfaces/playground';
import type { MutableRefObject } from 'react';
import ReactGA from 'react-ga4';

export const REGEX_HTTP =
  '^https://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|^https://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}';

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const nth = (d: number): string => {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const formatDate = (inputDate: string): string => {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  return `${day}${nth(day)} ${month} ${year}`;
};

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (isNaN(bytes)) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const blobToBase64 = async (
  blob: Blob
): Promise<ProgressEvent<FileReader>> => {
  return await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = (event: ProgressEvent<FileReader>) => {
      resolve(event);
    };
    reader.readAsDataURL(blob);
  });
};

export const downloadFile2LocalStorage = (data = 'file_correlations'): void => {
  const blobFile = data ?? localStorage.getItem(data);
  void fetch(blobFile)
    .then((res: Response) => {
      res
        .blob()
        .then((ress: Blob) => {
          const blobURL = URL.createObjectURL(ress);
          window.open(blobURL);
        })
        .catch((event) => {
          console.log(event);
        });
    })
    .catch((event) => {
      console.log(event);
    });
};

export const downloadDocumentFromUrl = (
  url: string,
  fileName: string
): void => {
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const includeURL = (url: string): boolean => {
  return (
    url !== undefined &&
    url !== '' &&
    typeof url === 'string' &&
    (url.includes('http') || url.includes('https') || url.includes('www'))
  );
};

export const GAUserEvent = (action: string, label = ''): void => {
  ReactGA.event({
    category: 'USER',
    action,
    label,
    nonInteraction: false
  });
};

export const GABotEvent = (action: string, label = ''): void => {
  ReactGA.event({
    category: 'BOT',
    action,
    label,
    nonInteraction: true
  });
};

type AnyObject = ArrayBuffer | Record<string, unknown>;

export const deepCopy = <T = AnyObject>(data: T): T => {
  if (typeof data === 'object' && data !== null) {
    if ('byteLength' in data) {
      return data;
    }
    const obj = (Array.isArray(data) ? [] : {}) as T;
    for (const key in data) {
      obj[key] = deepCopy(data[key]);
    }
    return obj;
  } else {
    return data;
  }
};

export const nameRegex = /^[a-zA-Z0-9\s()_\-,.*]*$/;
export const nameFileRegex = /^[a-zA-Z0-9_]+$/;

export const curringDataSearchParameters = (
  assetContext: {
    id: string;
    isView?: boolean;
    position?: number;
  },
  checks = false
) => {
  return (asset: Dataset | Model) => {
    if (asset.id === assetContext.id) {
      return true;
    } else {
      const { views } = asset as Dataset;
      if (views !== undefined && views.data.length > 0) {
        return Boolean(
          views.data.find((viewAsset, index) => {
            // If we are we are iterating selectedAssetsToMerge list we compare the viewAsset.id to the asset.id
            if (
              (checks && viewAsset.id === asset.id) ||
              (!checks && viewAsset.id === assetContext.id)
            ) {
              assetContext.isView = true;
              assetContext.position = index;
              return true;
            }
            return false;
          })
        );
      }
    }
    return false;
  };
};

type muttable = MutableRefObject<string | undefined>;

export const debouncer = (
  func: (e: string) => void,
  referencedValue: muttable,
  delay = 500
): void => {
  setTimeout(() => {
    if (
      referencedValue.current !== undefined &&
      referencedValue.current !== null
    ) {
      func(referencedValue.current);
    }
    referencedValue.current = undefined;
  }, delay);
};

export const transformDataToPlot = (parsedData: MessageDataPlot): Plot => {
  const plot: Plot = {
    title: parsedData.title,
    xlabel: parsedData.xlabel,
    ylabel: parsedData.ylabel,
    featureNames: parsedData.feature_names,
    featureImportance: parsedData.feature_importance,
    featurePlot: parsedData.feature_plot,
    featureHighImportance: parsedData.feature_high_importance,
    rightBarLabel: parsedData.right_bar_label,
    leftBarLabel: parsedData.left_bar_label
  };
  return plot;
};

export const lastMessageIsInference = (lastResponse: Blocks): boolean => {
  // TODO: This is temporary until we implement a way to keep track of the context of the conversation.
  const lastMessage = lastResponse.blocks[0]?.text?.text;
  if (lastMessage !== undefined) {
    return lastMessage === BACKEND_STRINGS.UTTER_UPLOAD_INFERENCE_DATA;
  }
  return false;
};

export const sanitizeFilename = (file: File): File => {
  const fileNameExtension = file.name.split('.').pop();
  const fileNameNoExtension = file.name.split('.').slice(0, -1).join('.');
  const fileNameNoSpecialChars = fileNameNoExtension.replace(/[^\w\d]+/g, '_');
  let newFileName;

  if (fileNameExtension !== undefined) {
    newFileName = `${fileNameNoSpecialChars}.${fileNameExtension}`;
  } else {
    newFileName = `${fileNameNoSpecialChars}`;
  }
  const newFile = new File([file], newFileName, {
    type: file.type
  });
  return newFile;
};

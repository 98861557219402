import type { CSSProperties } from 'react';

import { ReactComponent as ArrowLeft } from 'assets/imgs/icons/arrow-left.svg';
import { ReactComponent as BarChart } from 'assets/imgs/icons/bar-chart.svg';
import { ReactComponent as BulkPrediction } from 'assets/imgs/icons/bulk-prediction.svg';
import { ReactComponent as ChevronDown } from 'assets/imgs/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/imgs/icons/chevron-up.svg';
import { ReactComponent as Close } from 'assets/imgs/icons/close.svg';
import { ReactComponent as Combine01 } from 'assets/imgs/icons/combine-01.svg';
import { ReactComponent as CrossTabs } from 'assets/imgs/icons/cross-tabs.svg';
import { ReactComponent as EditContext } from 'assets/imgs/icons/edit-context.svg';
import { ReactComponent as DataTransformation } from 'assets/imgs/icons/data-transformation.svg';
import { ReactComponent as Data } from 'assets/imgs/icons/data.svg';
import { ReactComponent as Dotpoints01 } from 'assets/imgs/icons/dotpoints-01.svg';
import { ReactComponent as DotsVertical } from 'assets/imgs/icons/dots-vertical.svg';
import { ReactComponent as Download01 } from 'assets/imgs/icons/download-01.svg';
import { ReactComponent as Edit02 } from 'assets/imgs/icons/edit-02.svg';
import { ReactComponent as Expand } from 'assets/imgs/icons/expand.svg';
import { ReactComponent as EyeOff } from 'assets/imgs/icons/eye-off.svg';
import { ReactComponent as GenerateModel } from 'assets/imgs/icons/generate-model.svg';
import { ReactComponent as HelpCircle } from 'assets/imgs/icons/help-circle.svg';
import { ReactComponent as InfoCircle } from 'assets/imgs/icons/info-circle.svg';
import { ReactComponent as LastChild } from 'assets/imgs/icons/last-child.svg';
import { ReactComponent as Link03 } from 'assets/imgs/icons/link-03.svg';
import { ReactComponent as Lock01 } from 'assets/imgs/icons/lock-01.svg';
import { ReactComponent as LogOut } from 'assets/imgs/icons/log-out.svg';
import { ReactComponent as MergeData } from 'assets/imgs/icons/merge-data.svg';
import { ReactComponent as MidCild } from 'assets/imgs/icons/mid-child.svg';
import { ReactComponent as Minus } from 'assets/imgs/icons/minus.svg';
import { ReactComponent as OpenMySpace } from 'assets/imgs/icons/open-my-space.svg';
import { ReactComponent as Plus } from 'assets/imgs/icons/plus.svg';
import { ReactComponent as RefreshCCW01 } from 'assets/imgs/icons/refresh-ccw-01.svg';
import { ReactComponent as SelectModel } from 'assets/imgs/icons/select-model.svg';
import { ReactComponent as Settings03 } from 'assets/imgs/icons/settings-03.svg';
import { ReactComponent as SettingsFilters } from 'assets/imgs/icons/settings-filters.svg';
import { ReactComponent as SinglePrediction } from 'assets/imgs/icons/single-prediction.svg';
import { ReactComponent as Table } from 'assets/imgs/icons/table.svg';
import { ReactComponent as Trash01 } from 'assets/imgs/icons/trash-01.svg';
import { ReactComponent as UploadCloud } from 'assets/imgs/icons/upload-cloud-01.svg';
import { ReactComponent as User } from 'assets/imgs/icons/user-02.svg';
import { ReactComponent as Users01 } from 'assets/imgs/icons/users-01.svg';
import { ReactComponent as Chat } from 'assets/imgs/icons/chat.svg';
import { ReactComponent as DeleteReturn } from 'assets/imgs/icons/delete-return.svg';
import { ReactComponent as NavButtons } from 'assets/imgs/icons/nav-buttons.svg';
import { ReactComponent as Datasets } from 'assets/imgs/icons/datasets.svg';
import { ReactComponent as Models } from 'assets/imgs/icons/models.svg';

import { ICON_TYPE } from './interfaces/enums';

const defaultIconStyle: { width: string; height: string } = {
  width: '100%',
  height: '100%'
};

const customStyle = {
  maxWidth: '40px',
  margin: '5px',
  justifyContent: 'center',
  alignItems: 'center'
};

interface CustomIconProps {
  type: ICON_TYPE;
  className?: string;
  style?: CSSProperties;
  iconStyle?: CSSProperties;
}

const CustomIcon = ({
  type,
  style = customStyle,
  className,
  iconStyle
}: CustomIconProps): JSX.Element => {
  let Icon;
  switch (type) {
    case ICON_TYPE.SELECT_DATA:
      Icon = SelectModel;
      break;
    case ICON_TYPE.GENERATE_DATA:
      Icon = GenerateModel;
      break;
    case ICON_TYPE.USERS01:
      Icon = Users01;
      break;
    case ICON_TYPE.TRASH01:
      Icon = Trash01;
      break;
    case ICON_TYPE.EDIT02:
      Icon = Edit02;
      break;
    case ICON_TYPE.EYEOFF:
      Icon = EyeOff;
      break;
    case ICON_TYPE.DOWNLOAD01:
      Icon = Download01;
      break;
    case ICON_TYPE.HELP_CIRCLE:
      Icon = HelpCircle;
      break;
    case ICON_TYPE.LOCK01:
      Icon = Lock01;
      break;
    case ICON_TYPE.INFO_CIRCLE:
      Icon = InfoCircle;
      break;
    case ICON_TYPE.TABLE:
      Icon = Table;
      break;
    case ICON_TYPE.DOTPOINTS01:
      Icon = Dotpoints01;
      break;
    case ICON_TYPE.REFRESHCCW01:
      Icon = RefreshCCW01;
      break;
    case ICON_TYPE.BULK_PREDICTION:
      Icon = BulkPrediction;
      break;
    case ICON_TYPE.DATA:
      Icon = Data;
      break;
    case ICON_TYPE.COMBINE01:
      Icon = Combine01;
      break;
    case ICON_TYPE.SETTINGS03:
      Icon = Settings03;
      break;
    case ICON_TYPE.SETTINGS_FILTERS:
      Icon = SettingsFilters;
      break;
    case ICON_TYPE.LAST_CHILD:
      Icon = LastChild;
      break;
    case ICON_TYPE.MID_CHILD:
      Icon = MidCild;
      break;
    case ICON_TYPE.BAR_CHART:
      Icon = BarChart;
      break;
    case ICON_TYPE.LINK03:
      Icon = Link03;
      break;
    case ICON_TYPE.OPEN_MY_SPACE:
      Icon = OpenMySpace;
      break;
    case ICON_TYPE.UPLOAD_CLOUD_01:
      Icon = UploadCloud;
      break;
    case ICON_TYPE.MERGE_DATA:
      Icon = MergeData;
      break;
    case ICON_TYPE.SINGLE_PREDICTION:
      Icon = SinglePrediction;
      break;
    case ICON_TYPE.DATA_TRANSFORMATION:
      Icon = DataTransformation;
      break;
    case ICON_TYPE.CROSS_TABS:
      Icon = CrossTabs;
      break;
    case ICON_TYPE.EDIT_CONTEXT:
      Icon = EditContext;
      break;
    case ICON_TYPE.EXPAND:
      Icon = Expand;
      break;
    case ICON_TYPE.LOG_OUT:
      Icon = LogOut;
      break;
    case ICON_TYPE.USER:
      Icon = User;
      break;
    case ICON_TYPE.CHEVRON_DONW:
      Icon = ChevronDown;
      break;
    case ICON_TYPE.CHEVRON_UP:
      Icon = ChevronUp;
      break;
    case ICON_TYPE.ARROW_LEFT:
      Icon = ArrowLeft;
      break;
    case ICON_TYPE.PLUS:
      Icon = Plus;
      break;
    case ICON_TYPE.CLOSE:
      Icon = Close;
      break;
    case ICON_TYPE.DOTS_VERTICAL:
      Icon = DotsVertical;
      break;
    case ICON_TYPE.MINUS:
      Icon = Minus;
      break;
    case ICON_TYPE.CHAT:
      Icon = Chat;
      break;
    case ICON_TYPE.DELETE_RETURN:
      Icon = DeleteReturn;
      break;
    case ICON_TYPE.NAV_BAR:
      Icon = NavButtons;
      break;
    case ICON_TYPE.DATASETS:
      Icon = Datasets;
      break;
    case ICON_TYPE.MODELS:
      Icon = Models;
      break;
  }
  return Icon === undefined ? (
    <></>
  ) : (
    <div className={'display-row ' + (className ?? '')} style={style}>
      <Icon style={iconStyle ?? defaultIconStyle} />
    </div>
  );
};

export default CustomIcon;

import { Box, MenuItem, Select } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AIZWEILOGO from 'assets/imgs/aizwei-logo-black.svg';
import { modalControllerAtom } from 'atoms/atomModalController';
import {
  USER_TRACKING_LOCATION_NAMES,
  userTrackingLocation
} from 'atoms/atomUserLocation';
import { magicMessageDerived } from 'chat/atoms/atomMagicMessage';
import { type User } from 'models/User';
import { useEffect, useState, type ReactElement } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { GAUserEvent } from 'utils/utils';
import CustomButton from 'common/button/CustomButton';
import {
  HOME_VIEW_TYPES,
  ICON_TYPE,
  MODAL_TYPES
} from 'common/interfaces/enums';
import { Cookie } from 'utils/cookie';
import CustomIcon from 'common/CustomIcon';
import { KeyboardArrowDown } from '@mui/icons-material';
import { chatActiveAtom, homeViewRenderAtom } from 'home/atoms/AtomChatActive';

import './Navbar.scss';

const ACCOUNT = 'Account';
const LOGOUT = 'Logout';

const marketPlaceUrl = process.env.REACT_APP_MARKETPLACE_BASE_URL;
const marketPlacePrefixUri =
  process.env.REACT_APP_MARKETPLACE_BASE_URL_PREFIX_URI;
const plaionAccountId = process.env.REACT_APP_PLAION_ACCOUNT_ID;

interface NavBarProps {
  disabled: boolean;
  user: User | null;
  setValidToken: (validToken: boolean) => void;
}

const NavBar = ({
  disabled,
  user,
  setValidToken
}: NavBarProps): ReactElement => {
  const accountID = user?.accountId ?? user?.account?.id;
  const [modalController, setModalController] =
    useRecoilState(modalControllerAtom);
  const [chatActive, setChatActive] = useRecoilState(chatActiveAtom);
  const [userLocationVariable, setUserLocationVariable] =
    useRecoilState(userTrackingLocation);

  const [indexActiveTab, setIndexActiveTab] = useState(0);
  const [viewRender, setViewRender] = useRecoilState(homeViewRenderAtom);
  const resetViewRender = useResetRecoilState(homeViewRenderAtom);
  const setMagicMessage = useSetRecoilState(magicMessageDerived);

  const resetAtomModalController = useResetRecoilState(modalControllerAtom);

  const [isDisabled, setDisabled] = useState<boolean>(true);
  const [openSessionAdmin, setOpenSessionAdmin] = useState<boolean>(false);

  const userDisplayedName =
    user !== null
      ? `${user.firstName[0].toUpperCase() ?? ''}${
          user?.lastName[0].toUpperCase() ?? ''
        }`
      : '';

  useEffect(() => {
    setOpenSessionAdmin(false);
  }, [disabled]);

  useEffect(() => {
    if (indexActiveTab !== 0 && viewRender.type === HOME_VIEW_TYPES.CHAT) {
      setIndexActiveTab(0);
    }
    if (indexActiveTab === 0 && viewRender.stored !== undefined) {
      setViewRender({ ...viewRender.stored.render });
      setUserLocationVariable(viewRender.stored.userLocationVariable);
    }
  }, [viewRender, indexActiveTab]);

  const handleMySpace = (target: HOME_VIEW_TYPES, tabIndex: number): void => {
    if (tabIndex === 0 && indexActiveTab === 0) {
      return;
    }
    if (indexActiveTab === 0 && tabIndex !== 0) {
      setViewRender({
        type: target,
        stored: { render: viewRender, userLocationVariable }
      });
      setUserLocationVariable({
        ...userLocationVariable,
        current: USER_TRACKING_LOCATION_NAMES.MYSPACE,
        previous: userLocationVariable.current
      });
    } else {
      setViewRender({ type: target, stored: viewRender.stored });
    }
    GAUserEvent(target);
    setIndexActiveTab(tabIndex);
  };

  const handleLogout = (): void => {
    if (chatActive || modalController.type !== MODAL_TYPES.START_CHAT) {
      setMagicMessage(undefined);
      Cookie.remove('token');
      setModalController({ type: MODAL_TYPES.START_CHAT });
      setChatActive(false);
      setValidToken(false);
      resetViewRender();
      resetAtomModalController();
    }
  };

  const handleSessionAdmin = (target?: string): void => {
    setOpenSessionAdmin(!openSessionAdmin);
    if (target === LOGOUT) {
      GAUserEvent('LOGOUT');
      handleLogout();
    } else if (target === ACCOUNT) {
      GAUserEvent('OPEN_ACCOUNT');
      openMarketPlace();
    }
  };

  const openMarketPlace = (): void => {
    if (marketPlaceUrl !== undefined && marketPlacePrefixUri !== undefined) {
      window.location.href = `${marketPlaceUrl}${marketPlacePrefixUri}`;
    }
  };

  return (
    <Box id="nav-bar">
      <Box id="nav-bar__menu">
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          disabled={isDisabled}
          onClick={() => {
            if (!isDisabled) {
              setDisabled(true);
              handleLogout();
            }
          }}
        >
          <img src={AIZWEILOGO} alt="AIZWEI LOGO" />
        </IconButton>
        {chatActive && user !== null ? (
          <>
            <Box
              className={`${
                indexActiveTab === 0 ? 'navbar-selected-tab' : ''
              } nav-bar__menu-item`}
              onClick={() => {
                handleMySpace(HOME_VIEW_TYPES.CHAT, 0);
              }}
            >
              <CustomIcon type={ICON_TYPE.NAV_BAR} />
              Aizy
            </Box>
            <Box
              className={`${
                indexActiveTab === 1 ? 'navbar-selected-tab' : ''
              } nav-bar__menu-item`}
              onClick={() => {
                handleMySpace(HOME_VIEW_TYPES.LOAD_DATASETS, 1);
              }}
            >
              <CustomIcon type={ICON_TYPE.DATASETS} />
              Datasets
            </Box>
            <Box
              className={`${
                indexActiveTab === 2 ? 'navbar-selected-tab' : ''
              } nav-bar__menu-item`}
              onClick={() => {
                handleMySpace(HOME_VIEW_TYPES.LOAD_MODELS, 2);
              }}
            >
              <CustomIcon type={ICON_TYPE.MODELS} />
              Models
            </Box>
            {accountID === plaionAccountId ? (
              <CustomButton variant="tertiary">
                <a
                  className="ft-bold"
                  style={{ color: 'inherit', textDecoration: 'none' }}
                  href="https://dailightai.sharepoint.com/:b:/s/AIZWEI2/EVZRsfsCPD1FqjHtGCl-3t0Bz0CyjjQvginqs3AbUksCGw?e=N9ZRGN"
                  rel="noreferrer"
                  target="_blank"
                >
                  Report
                </a>
              </CustomButton>
            ) : null}
          </>
        ) : (
          <></>
        )}
      </Box>
      <Box id="nav-bar__privacy-link">
        {chatActive && user !== null ? (
          <>
            <Select
              labelId="navbar-account-label"
              className="navbar-account-select"
              value={userDisplayedName}
              renderValue={() => (
                <Box className="navbar-render-account-select">
                  <CustomIcon type={ICON_TYPE.USER} />
                  {userDisplayedName}
                </Box>
              )}
              IconComponent={KeyboardArrowDown}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center'
                },
                transformOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center'
                },
                PaperProps: {
                  sx: {
                    borderRadius: '16px',
                    transform: 'translateY(-10px)!important'
                  }
                }
              }}
              onChange={({ target }) => {
                handleSessionAdmin(target.value);
              }}
            >
              <MenuItem
                className="nav-bar-account-menu-item"
                key={ACCOUNT + '0'}
                value={ACCOUNT}
              >
                {ACCOUNT}
              </MenuItem>
              <MenuItem
                className="nav-bar-account-menu-item"
                key={LOGOUT + '0'}
                value={LOGOUT}
              >
                {LOGOUT}
              </MenuItem>
            </Select>
          </>
        ) : (
          <></>
        )}
        <a target="_blank" rel="noreferrer" href="https://aizwei.com/privacy">
          Privacy policy
        </a>
      </Box>
    </Box>
  );
};

export default NavBar;
